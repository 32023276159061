<template>
  <b-card>

    <div class="d-flex justify-content-start">
      <b-avatar
        :src="logo"
        :text="dadoCliente.nome.substr(0, 2)"
        size="70px"
        rounded
      />
      <div class="d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0">
            {{ dadoCliente.nome }}
          </h4>
          <span class="card-text">{{ dadoCliente.cnpj }}</span>
          <b-row>
            <b-badge
              v-if="dadoCliente.status"
              class="ml-1"
              variant="success"
            >
              Ativo
            </b-badge>
            <b-badge
              v-else
              class="ml-1"
            >
              Inativo
            </b-badge>
          </b-row>
        </div>
      </div>
    </div>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="8">
          <b-form-group
            label="Nome"
            label-for="nome"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.nome"
                name="nome"
                placeholder="Nome"
                :state="nameState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.nome"
                name="nome"
                placeholder="nome"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="CPF/CNPJ"
            label-for="cpf-cnpj"
            :state="cnpjState"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.cnpj"
                class="form-control"
                :raw="false"
                :options="mask.cnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
                :state="cnpjState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.cnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="8">
          <b-form-group
            label="Razão Social"
            label-for="razao-social"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.razaoSocial"
                name="razao-social"
                placeholder="Razão Social"
                :state="razaoSocialState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.razaoSocial"
                name="razao-social"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="CEP"
            label-for="cep"
          >
            <div v-if="editar">
              <b-input-group>
                <cleave
                  v-model="dadoCliente.cep"
                  class="form-control"
                  name="cep"
                  size="sm"
                  :raw="false"
                  :options="mask.cep"
                  placeholder="00000-000"
                  @keyup.enter="consultaCEP"
                />
                <b-input-group-append>
                  <b-button
                    size="md"
                    variant="outline-primary"
                    @click="consultaCEP"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.cep"
                name="cep"
                placeholder="CEP"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Alias Completo"
            label-for="alias-completo"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.aliasCompleto"
                name="alias-completo"
                placeholder="Alias Completo"
                :state="aliasCompletoState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.aliasCompleto"
                name="telefone"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Alias Abreviado"
            label-for="alias-abreviado"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.aliasAbreviado"
                name="alias-abreviado"
                placeholder="Alias Abreviado"
                :state="aliasAbreviadoState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.aliasAbreviado"
                name="telefone"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Inscrição Estadual"
            label-for="inscricao-estadual"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.inscricaoEstadual"
                name="inscricao-estadual"
                placeholder="Inscrição Estadual"
                :state="inscricaoEstadualState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.inscricaoEstadual"
                name="telefone"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Email"
            label-for="email"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.email"
                name="email"
                placeholder="Email"
                :state="emailState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.email"
                name="email"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Grupo de credores"
            label-for="grupo"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.grupoCredores"
                name="grupo"
                placeholder="Grupo de credores"
                :state="grupoState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.grupoCredores"
                name="telefone"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Telefone"
            label-for="telefone"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.telefone"
                class="form-control"
                :raw="false"
                :options="mask.telefone"
                name="telefone"
                placeholder="Telefone"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.telefone"
                name="telefone"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Rua"
            label-for="rua"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.endereco"
                name="rua"
                placeholder="Rua"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.endereco"
                name="rua"
                placeholder="Rua"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Número"
            label-for="numero"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.numero"
                name="numero"
                placeholder="Número"
                :state="numeroState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.numero"
                name="numero"
                placeholder="Número"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Bairro"
            label-for="bairro"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.bairro"
                name="bairro"
                placeholder="Bairro"
                :state="bairroState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.bairro"
                name="bairro"
                placeholder="Bairro"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Cidade"
            label-for="cidade"
          >
            <div v-if="editar">
              <b-form-group
                v-model="dadoCliente.cidade"
                name="cidade"
                placeholder="Cidade"
              >
                <v-select
                  v-model="dadoCliente.cidade"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cidadesOpcoes"
                />
              </b-form-group>
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.cidade"
                name="cidade"
                placeholder="Cidade"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col
          sm="4"
          hidden
        >
          <b-form-group
            label="Status em Processo"
            label-for="status-em-processo"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.statusemProcesso"
                name="status-em-processo"
                placeholder="Status em Processo"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.statusemProcesso"
                name="status-em-processo"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="3"
          hidden
        >
          <b-form-group
            label="Status não Localizado"
            label-for="status-nao-localizado"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.statusnaoLocalizado"
                name="status-nao-localizado"
                placeholder="Status não Localizado"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.statusnaoLocalizado"
                name="status-nao-localizado"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Recebimentos"
            label-for="recebimentos"
          >
            <div v-if="editar">
              <b-form-group
                v-model="dadoCliente.recebimento"
                name="recebimento"
                placeholder="Recebimentos"
                :state="recebimentoState"
                required
              >
                <v-select
                  v-model="dadoCliente.recebimento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                />
              </b-form-group>
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.recebimento"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="options"
                placeholder="Recebimentos"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Tipo de Remessa"
            label-for="tipoRemessa"
          >
            <div v-if="editar">
              <b-form-group
                v-model="dadoCliente.tipoRemessa"
                name="tipoRemessa"
                placeholder="Tipo de Remessa"
                :state="tipoRemessaState"
                required
              >
                <v-select
                  v-model="dadoCliente.tipoRemessa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsTipoRemessa"
                />
              </b-form-group>
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.tipoRemessa"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsTipoRemessa"
                placeholder="Tipo de Remessa"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Dias Extração"
            label-for="diasExtracao"
          >
            <div v-if="editar">
              <b-form-group
                v-model="dadoCliente.diasExtracao"
                name="diasExtracao"
                placeholder="Tipo de Remessa"
                :state="diasExtracaoState"
                required
              >
                <v-select
                  v-model="dadoCliente.diasExtracao"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsDiasExtracao"
                  multiple
                />
              </b-form-group>
            </div>
            <div v-else>
              <b-form-input
                :value="diasFormated(dadoCliente.diasExtracao)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Dias Extração"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Prazo Remessa"
            label-for="prazo-remessa"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.prazoRemessa"
                name="prazo-remessa"
                placeholder="Prazo de remessa"
                :state="prazoRemessaState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.prazoRemessa"
                name="prazo-remessa"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Prazo Honorário"
            label-for="prazo-honorario"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.prazoHonorario"
                name="prazo-honorario"
                placeholder="Prazo Honorário"
                :state="prazoHonorarioState"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.prazoHonorario"
                name="prazo-honorario"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group
            label="TIPO CREDOR"
            label-for="TIPO CREDOR"
          >
            <div v-if="editar">
              <v-select
                v-model="dadoCliente.tipoCredor"
                placeholder="Tipo do credor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsTipoCredor"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.tipoCredor"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="1">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <div v-if="editar">
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.status"
                  :checked="dadoCliente.status"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div v-else>
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.status"
                  :checked="dadoCliente.status"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  disabled
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="1">
          <b-form-group
            label="Negativação"
            label-for="negativacao"
          >
            <div v-if="editar">
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.negativacao"
                  :checked="dadoCliente.negativacao"
                  class="custom-control-success ml-1"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div v-else>
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.negativacao"
                  :checked="dadoCliente.negativacao"
                  class="custom-control-success ml-1"
                  name="check-button"
                  switch
                  disabled
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Gerar Número Operação"
            label-for="gerar-numero-operacao"
          >
            <div v-if="editar">
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.gerarNumeroOperacao"
                  :checked="dadoCliente.gerarNumeroOperacao"
                  class="custom-control-success ml-3"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div v-else>
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.gerarNumeroOperacao"
                  :checked="dadoCliente.gerarNumeroOperacao"
                  class="custom-control-success ml-3"
                  name="check-button"
                  switch
                  disabled
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>

        </b-col>
        <!-- alert -->
        <!-- <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Cadastro incompleto
            </h4>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col
          v-if="permissions['credores.index'].permissao"
          cols="12"
        >
          <div v-if="!editar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="btEditarCliente"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateCliente"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Salvar</span>
            </b-button>
          </div>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol,
  // BAlert,
  BCard, BAvatar, BFormCheckbox, BBadge, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    BAvatar,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      refInputEl,
      previewEl,
    }
  },
  data() {
    return {
      permissions: null,
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 5, 4],
          uppercase: true,
        },
      },
      // eslint-disable-next-line global-require
      logo: '',
      editar: false,
      status: true,
      dadoCliente: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      options: ['Conta Voz', 'Conta Credor'],
      optionsTipoRemessa: ['ENVIADA', 'EXTRAIDA'],
      optionsDiasExtracao: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      optionsTipoCredor: [{ label: 'ESCOLA', value: 'ESCOLA' }, { label: 'FACULDADE', value: 'FACULDADE' }, { label: 'PÓS-GRADUAÇÃO', value: 'POS GRADUACAO' }],
      diasExtracao: [],
      prazoHonorario: null,
      tipoRemessa: null,
      cidadesOpcoes: [],
      cidade: '',
    }
  },
  computed: {
    nameState() {
      if (this.dadoCliente.nome) {
        return true
      }
      return false
    },
    razaoSocialState() {
      if (this.dadoCliente.razaoSocial) {
        return true
      }
      return false
    },
    inscricaoEstadualState() {
      if (this.dadoCliente.inscricaoEstadual) {
        return true
      }
      return false
    },
    aliasCompletoState() {
      if (this.dadoCliente.aliasCompleto) {
        return true
      }
      return false
    },
    grupoState() {
      if (this.dadoCliente.grupoCredores) {
        return true
      }
      return false
    },
    aliasAbreviadoState() {
      if (this.dadoCliente.aliasAbreviado) {
        return true
      }
      return false
    },
    cnpjState() {
      if (this.dadoCliente.cnpj) {
        return true
      }
      return false
    },
    cepState() {
      if (this.dadoCliente.cep) {
        return true
      }
      return false
    },
    ruaState() {
      if (this.dadoCliente.rua) {
        return true
      }
      return false
    },
    numeroState() {
      if (this.dadoCliente.numero) {
        return true
      }
      return false
    },
    bairroState() {
      if (this.dadoCliente.bairro) {
        return true
      }
      return false
    },
    cidadeState() {
      if (this.dadoCliente.cidade) {
        return true
      }
      return false
    },
    telefoneState() {
      if (this.dadoCliente.telefone) {
        return true
      }
      return false
    },
    emailState() {
      if (this.dadoCliente.email) {
        return true
      }
      return false
    },
    recebimentoState() {
      if (this.dadoCliente.recebimento) {
        return true
      }
      return false
    },
    tipoRemessaState() {
      if (this.dadoCliente.tipoRemessa) {
        return true
      }
      return false
    },
    diasExtracaoState() {
      if (this.dadoCliente.diasExtracao) {
        return true
      }
      return false
    },
    prazoHonorarioState() {
      if (this.dadoCliente.prazoHonorario) {
        return true
      }
      return false
    },
    prazoRemessaState() {
      if (this.dadoCliente.prazoRemessa) {
        return true
      }
      return false
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).credores
    this.listarCidades()
  },
  methods: {
    diasFormated(diasExtracao) {
      if (diasExtracao) {
        return Object.values(diasExtracao)
          .filter(valor => typeof valor === 'number')
          .join(', ')
      }
      return null
    },
    resetForm() {
      this.dadoCliente = JSON.parse(JSON.stringify(this.generalData))
      this.editar = false
    },
    updateCliente() {
      const formData = {
        nome: this.dadoCliente.nome,
        rede_id: this.dadoCliente.grupoCredores,
        cnpj: this.dadoCliente.cnpj.replace(/[^0-9]/g, ''),
        razao_social: this.dadoCliente.razaoSocial,
        inscricao_estadual: this.dadoCliente.inscricaoEstadual,
        alias_completo: this.dadoCliente.aliasCompleto,
        alias_abreviado: this.dadoCliente.aliasAbreviado,
        email: this.dadoCliente.email,
        telefone: this.dadoCliente.telefone ? this.dadoCliente.telefone.replace(/[^0-9]/g, '') : null,
        endereco: this.dadoCliente.endereco,
        cep: this.dadoCliente.cep ? this.dadoCliente.cep.replace(/[^0-9]/g, '') : null,
        numero: this.dadoCliente.numero,
        logradouro: this.dadoCliente.rua,
        bairro: this.dadoCliente.bairro,
        recebimento: this.dadoCliente.recebimento,
        prazo_honorario: this.dadoCliente.prazoHonorario,
        tipo_remessa: this.dadoCliente.tipoRemessa,
        dias_extracao: JSON.stringify(this.dadoCliente.diasExtracao),
        cidade: this.dadoCliente.cidade,
        cidade_id: this.dadoCliente.cidade_id.id,
        status_em_processo: this.dadoCliente.statusemProcesso,
        status_nao_localizado: this.dadoCliente.statusnaoLocalizado,
        status: this.dadoCliente.status,
        prazo_remessa: this.dadoCliente.prazoRemessa,
        negativacao: this.dadoCliente.negativacao,
        gerar_numero_operacao: this.dadoCliente.gerarNumeroOperacao,
        tipo_credor: this.dadoCliente.tipoCredor.value,
      }
      axios.put(`api/v1/credores/update/${this.dadoCliente.id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.dadoCliente.tipoCredor = this.dadoCliente.tipoCredor.label
          this.editar = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${res.data.mensagem}`,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Erro ao salvar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async consultaCEP() {
      const cep = this.dadoCliente.cep.replace(/[^0-9]/g, '')
      await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.dadoCliente.numero = ''
          this.dadoCliente.endereco = res.data.logradouro
          this.dadoCliente.bairro = res.data.bairro
          this.dadoCliente.cidade = res.data.localidade
          this.dadoCliente.uf = res.data.uf
        })
    },
    btEditarCliente() {
      this.editar = true
    },
    async listarCidades() {
      this.cidadesOpcoes = []
      await axios
        .get('api/v1/cidades', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          res.data.dados.map(cidade => {
            this.cidadesOpcoes.push({
              label: cidade.nome.toUpperCase(),
              id: cidade.id,
            })
          })
        })
    },
  },
}
</script>
