<template>
  <div>
    <b-row>
      <b-col>
        <section
          v-if="dadosGraficos"
          id="dashboard-ecommerce"
        >
          <b-card>
            <ChartRemessasTotal
              :general-data="generalData"
            />
          </b-card>
          <b-card>
            <ChartRemessasAnual
              :general-data="generalData"
            />
          </b-card>
          <b-card>
            <ChartFaturamentoTotal
              :general-data="generalData"
            />
          </b-card>
          <b-card>
            <ChartFaturamentoAnual
              :general-data="generalData"
            />
          </b-card>
          <b-row
            align-v="stretch"
          >
            <b-col
              cols="6"
            >
              <b-card
                :title="`Cobranças - R$ ${valorBr(cobrancaSoma)} `"
              >
                <chartjs-bar-chart
                  :general="generalData"
                  @somaCobranca="somaCobranca"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Acordos - ${totalAcordo}`"
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusAcordos.chartOptions"
                  :series="statusAcordos.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Operações - ${totalOperacao}`"
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusOperacoes.chartOptions"
                  :series="statusOperacoes.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Pendências - ${totalPendencia}`"
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusPendencias.chartOptions"
                  :series="statusPendencias.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Localizado - ${totalCliente}`"
              >
                <echart-doughnut
                  :data="localizacao"
                />
              </b-card>
            </b-col>
            <b-col
              v-if="graficoPerfil"
              cols="6"
            >
              <b-card
                :title="`Perfil Cliente - ${totalClientes}`"
              >
                <echart-doughnut
                  :data="clientes"
                  @chartClick="openModalPerfil"
                />
              </b-card>
            </b-col>
            <b-modal
              ref="perfilModal"
              :title="`${nomePerfil}`"
              size="lg"
            >
              <b-button
                variant="outline-warning"
                style="margin-bottom: 10px; float: right;"
                @click="exportFilePerfil"
              >
                Exportar excel
              </b-button>
              <b-table
                ref="selectableTable"
                striped
                bordered
                responsive
                :current-page="currentPage"
                :per-page="perPage"
                :fields="fieldsPerfil"
                :items="perfil[0]"
                class="text-center"
              >
                <template #cell(cpf_cnpj)="data">
                  {{ data.item.cpf_cnpj ? cpfCNPJ(data.item.cpf_cnpj, true) : '' }}
                </template>
                <template #cell(nomePerfil)>
                  {{ nomePerfil }}
                </template>
              </b-table>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  align="center"
                />
              </b-col>
            </b-modal>
          </b-row>
        </section>
      </b-col>
    </b-row>
    <b-overlay
      :show="load"
      variant="transparent"
      rounded="sm"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BModal,
  BButton,
  BTable,
  BPagination,
} from 'bootstrap-vue'

import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import { saveAs } from 'file-saver'
import tree from '@/navigation/vertical'
import ChartjsBarChart from './charts/ChartjsBarChart.vue'
import EchartDoughnut from './charts/EchartDoughnut.vue'
import ChartRemessasTotal from './charts/ChartRemessasTotal.vue'
import ChartRemessasAnual from './charts/ChartRemessasAnual.vue'
import ChartFaturamentoTotal from './charts/ChartFaturamentoTotal.vue'
import ChartFaturamentoAnual from './charts/ChartFaturamentoAnual.vue'

import api from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    BOverlay,
    ChartRemessasTotal,
    ChartRemessasAnual,
    ChartFaturamentoTotal,
    ChartFaturamentoAnual,
    ChartjsBarChart,
    EchartDoughnut,
    BModal,
    BButton,
    BTable,
    BPagination,

  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      nomePerfil: '',
      perfil: [],
      selectedPerfil: '',
      credorId: null,
      fieldsPerfil: [
        {
          key: 'nome_cliente', label: 'Cliente',
        },
        {
          key: 'cpf_cnpj', label: 'CPF/CNPJ',
        },
        {
          key: 'nomePerfil', label: 'Perfil',
        },
      ],
      graficoPerfil: false,
      load: true,
      dados: null,
      credores: [],
      credoresFiltro: [],
      localizacao: [],
      clientes: [],
      totalAcordo: 0,
      totalOperacao: 0,
      totalPendencia: 0,
      totalCliente: 0,
      totalClientes: 0,
      cobrancaSoma: 0,
      cobrancaSomaMes: 0,
      selectedYear: null,
      years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      statusAcordos: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#662E9B'],
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          // yaxis: {
          //   // opposite: isRtl,
          // },
        },
      },
      statusOperacoes: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#008000'],
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },

      statusPendencias: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },
    }
  },

  created() {
    this.getGraficos()
    this.getPerfil()
  },

  methods: {
    async openModalPerfil(data) {
      this.perfil = []
      let selectedPerfil = ''
      switch (data.name) {
        case 'Crônico':
          selectedPerfil = 'Crônico'
          break
        case 'Devolvido':
          selectedPerfil = 'Devolvido'
          break
        case 'Ocasional':
          selectedPerfil = 'Ocasional'
          break
        case 'Negligente':
          selectedPerfil = 'Negligente'
          break
        case 'Mau Pagador':
          selectedPerfil = 'Mau Pagador'
          break
        default:
          break
      }

      this.credorId = this.generalData
      const body = {
        credor_id: this.credorId,
      }
      await api.post('api/v1/credores/clientes_perfis', body)
        .then(res => {
          Object.keys(res.data.dados).forEach(key => {
            const item = res.data.dados[key]
            if (key === selectedPerfil) {
              this.perfil.push(item)
            }
          })
        })
      this.totalRows = this.perfil[0].length
      this.nomePerfil = data.name
      this.$refs.perfilModal.show()
    },
    async exportFilePerfil() {
      const file = await this.getDataToExportedPerfil()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExportedPerfil() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Perdil de Cliente')
      const itemsPlan = this.perfil[0]
      worksheet.columns = [
        { header: 'Nome Cliente', key: 'nome_cliente' },
        { header: 'Cpf/Cnpj', key: 'cpf_cnpj' },
        { header: 'Perfil', key: this.nomePerfil },
      ]
      itemsPlan.map(item => {
        worksheet.addRow([
          item.nome_cliente,
          item.cpf_cnpj,
          this.nomePerfil,
        ])
      })
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })
      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Perfil de cliente', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    somaCobranca(soma) {
      this.cobrancaSoma = soma
    },
    somaCobrancaMes(soma) {
      this.cobrancaSomaMes = soma
    },
    getGraficos() {
      const body = {
        credores: [this.generalData],
      }
      api.post('api/v1/credores/totalizadores/status', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.localização.map(loc => {
          if (loc.localizacao === null) {
            loc.localizacao = 'Em Processamento'
          }
        })
        const dt = res.data.dados
        this.load = false
        this.dadosGraficos = true

        // GRAFICO ACORDOS
        const totalCredor = []
        const statusAcordo = []

        dt.status_acordo.map(status => {
          totalCredor.push(status.total)
        })
        this.statusAcordos.series[0].data = totalCredor

        dt.status_acordo.map(statusA => {
          statusAcordo.push(statusA.status)
        })
        this.statusAcordos.chartOptions.xaxis.categories = statusAcordo

        dt.status_acordo.map(soma => {
          this.totalAcordo += parseFloat(soma.total)
        })

        // GRAFICO LOCALIZAÇÃO
        this.localizacao = []
        dt.localização.map(total => {
          this.localizacao.push({
            name: total.localizacao,
            value: total.total,
          })
        })

        dt.localização.map(soma => {
          this.totalCliente += parseFloat(soma.total)
        })

        // GRAFICO OPERACOES
        const operacaoCredorTotal = []
        const operacaoCredor = []
        dt.operacao.map(ot => {
          operacaoCredorTotal.push(ot.total)
        })
        this.statusOperacoes.series[0].data = operacaoCredorTotal

        dt.operacao.map(on => {
          operacaoCredor.push(on.nome)
        })
        this.statusOperacoes.chartOptions.xaxis.categories = operacaoCredor

        dt.operacao.map(soma => {
          this.totalOperacao += parseFloat(soma.total)
        })

        // GRAFICO PENDENCIA
        const pendenciaCredorTotal = []
        const pendenciaCredor = []
        dt.motivo_pendencia.map(mt => {
          pendenciaCredorTotal.push(mt.total)
        })
        this.statusPendencias.series[0].data = pendenciaCredorTotal
        dt.motivo_pendencia.map(mn => {
          pendenciaCredor.push(mn.nome)
        })
        this.statusPendencias.chartOptions.xaxis.categories = pendenciaCredor

        dt.motivo_pendencia.map(soma => {
          this.totalPendencia += parseFloat(soma.total)
        })
      })
    },
    getPerfil() {
      api.get('api/v1/perfil_cliente/listar/credor', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.graficoPerfil = true
        // GRAFICO PERFIL
        const clientesPorPerfil = dt.reduce((acc, curr) => {
          if (curr.credor_id === this.generalData) {
            curr.clientes.forEach(total => {
              const { perfil } = total
              if (acc[perfil]) {
                acc[perfil]++
              } else {
                acc[perfil] = 1
              }
            })
          }
          return acc
        }, {})
        this.clientes = Object.keys(clientesPorPerfil).map(perfil => ({
          name: perfil,
          value: clientesPorPerfil[perfil],
        }))
        this.totalClientes = this.clientes.reduce((acc, curr) => acc + curr.value, 0)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
