<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card>
              <b-row class="justify-content-between">
                <b-col>
                  <b-row>
                    <b-col>
                      <h5><strong>Parâmetros da Carteira</strong></h5>
                    </b-col>
                    <b-col
                      class="text-right"
                    >
                      <div v-if="permissions['credores.storeParametros'].permissao">
                        <b-button
                          v-if="defaultEdit"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="defaultEdit = false "
                        >
                          Editar
                        </b-button>
                        <b-button
                          v-if="!defaultEdit"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="setDefaultParametro"
                        >
                          Salvar
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="1">
                  <label for=""><strong>Portal</strong></label>
                  <br>
                  <b-form-checkbox
                    v-model="dft.ativo_portal"
                    name="check-button"
                    :disabled="defaultEdit"
                    switch
                    inline
                  />
                </b-col>

                <b-col>
                  <label for=""><strong>Convênio</strong></label>
                  <v-select
                    v-model="dft.convenio"
                    :options="convenios"
                    :disabled="defaultEdit"
                    :get-option-label="convenio => `${convenio.convenio} - ${convenio.nome_banco}`"
                  />
                </b-col>

                <b-col>
                  <label for=""><strong>Correção Monetário</strong></label>
                  <v-select
                    v-model="dft.correcao_monetario"
                    :options="correcaoMonetarias"
                    :disabled="defaultEdit"
                  />
                </b-col>

                <b-col>
                  <label for=""><strong>Opção Cartão</strong></label>
                  <v-select
                    v-model="dft.opcao_cartao"
                    :options="opcaoCartao"
                    multiple
                    :disabled="defaultEdit"
                  />
                </b-col>
                <b-col md="2">
                  <label for=""><strong>Base Parcela</strong></label>
                  <money
                    v-model="dft.base_parcela"
                    v-bind="money"
                    class="form-control"
                    :disabled="defaultEdit"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="3">
                  <label for="">Hon. Fixo ano em curso</label>
                  <b-form-input
                    v-model="dft.comissao_fixa"
                    type="number"
                    :disabled="defaultEdit"
                  />
                </b-col>
                <b-col md="3">
                  <label for="">Hon. Fixo Baixa Credor</label>
                  <b-form-input
                    v-model="dft.comissao_baixa_credor"
                    type="number"
                    :disabled="defaultEdit"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="12">
                  <label for="">Oberservações Gerais</label>

                  <quill-editor
                    v-model="dft.observacoes"
                    :disabled="defaultEdit"
                  />

                  <!--
                  <b-form-textarea
                    id="textarea-auto-height"
                    v-model="dft.observacoes"
                    rows="1"
                    max-rows="2"
                    :disabled="defaultEdit"
                  />
                  -->
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-left mb-1">
            <b-button
              v-if="permissions['credores.storeParametros'].permissao"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="!defaultEdit"
              @click="openModalnewParametro"
            >
              Novo Paramêtro
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-table
                flex
                class="position-relative"
                :fields="fields"
                :items="items"
                @row-selected="onRowSelected"
              >
                <template #cell(semestre)="sem">
                  <div v-if="isSituacao">
                    {{ sem.item.semestre.value === '1' ? 'ATIVO' : 'INATIVO' }}
                  </div>
                  <div v-else>
                    {{ sem.item.semestre === '1' || sem.item.semestre === '2' ? sem.item.semestre : Object.keys(sem.item.semestre) }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    :disabled="!defaultEdit"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="updateParametro(data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="setModalDuplicar(data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Duplicar</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="deleteParametro(data.item)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Deletar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      ref="newParametro"
      size="xl"
      no-close-on-backdrop
      ok-only
      ok-title="Salvar"
      :ok-disabled="!yearSelected && !semestreSelected && !remuneracaoSelected"
      @ok="setNewParametro"
      @hide="clearForm"
    >
      <b-row class="mb-1">
        <b-col>
          <b-row>
            <b-col>
              <h4>Período</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="">Selecione o ano</label>
              <v-select
                v-model="yearSelected"
                :options="setAnosDisponiveis"
                :disabled="periodoDisabled"
              />
            </b-col>
            <b-col v-if="!isSituacao">
              <label for="">Selecione o semestre</label>
              <v-select
                v-model="semestreSelected"
                :options="setSemestres"
                :disabled="periodoDisabled"
              />
            </b-col>
            <b-col v-if="isSituacao">
              <label for="">Selecione a Situação</label>
              <v-select
                v-model="semestreSelected"
                :options="setSemestres"
                :reduce="sem => sem.value"
                label="label"
                :disabled="periodoDisabled"
              />
            </b-col>
            <b-col>
              <label for="">Selecione a Remuneração</label>
              <v-select
                v-model="remuneracaoSelected"
                :options="remuneracao"
                label="label"
              />
            </b-col>
            <b-col>
              <label for="">Limitar Cartão</label> <br>
              <b-form-checkbox
                v-model="limitar_cartao"
                switch
                inline
              />
            </b-col>

            <b-col>
              <label for="">Limitar Boleto</label> <br>
              <b-form-checkbox
                v-model="limitar_boleto"
                switch
                inline
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div v-if="yearSelected && semestreSelected && remuneracaoSelected">
        <hr>
        <b-row v-if="avista.avaible">
          <b-col>
            <b-row class="">
              <b-col>
                <h4>Avista</h4>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <b-tabs>
                  <b-tab
                    v-for="fPagamento in Object.keys(avista).filter(e => e !== 'avaible')"
                    :key="fPagamento"
                  >
                    <template #title>
                      <feather-icon icon="CpuIcon" />
                      <span>{{ avista[fPagamento].label }}</span>
                    </template>
                    <b-form-checkbox
                      v-model="avista[fPagamento].avaible"
                      name="check-button"
                      switch
                      inline
                    />
                    <b-row v-if="avista[fPagamento].avaible">
                      <b-col
                        v-for="item in Object.keys(avista[fPagamento]).filter(e => e !== 'avaible' && e !== 'label')"
                        :key="item"
                        :md="item === 'desconto_concedido' ? 2 : ''"
                      >
                        <div v-if="item === 'desconto_concedido'">
                          <label for=""><strong>Desconto Concedido</strong></label>
                          <v-select
                            v-model="avista[fPagamento][item]"
                            :clearable="false"
                            :options="descontoConcedido"
                          />
                        </div>
                        <div v-else>
                          <label class="text-uppercase">{{ item.includes('_max') ? item.split('_').join(' ') : item.concat(' MIN') }}</label>
                          <b-form-input
                            v-model="avista[fPagamento][item]"
                            type="number"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr class="mb-1">

        <b-row v-if="parcelado.avaible">
          <b-col>
            <b-row class="">
              <b-col>
                <h4>Parcelado</h4>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <b-tabs>
                  <b-tab
                    v-for="fPagamento in Object.keys(parcelado).filter(e => e !== 'avaible')"
                    :key="fPagamento"
                  >
                    <template #title>
                      <feather-icon icon="CpuIcon" />
                      <span>{{ parcelado[fPagamento].label }}</span>
                    </template>
                    <div v-if="fPagamento === 'cartao_credito'">
                      <b-form-checkbox
                        v-model="parcelado[fPagamento].avaible"
                        name="check-button"
                        :disabled="verifyOpcaoCartao"
                        switch
                        inline
                      />
                    </div>
                    <div v-else>
                      <b-form-checkbox
                        v-model="parcelado[fPagamento].avaible"
                        name="check-button"
                        switch
                        inline
                      />
                    </div>
                    <b-row v-if="parcelado[fPagamento].avaible">
                      <b-col
                        v-for="item in Object.keys(parcelado[fPagamento]).filter(e => e !== 'avaible' && e !== 'label')"
                        :key="item"
                        :md="item === 'desconto_concedido' ? 2 : ''"
                      >
                        <div v-if="item === 'desconto_concedido'">
                          <label class="text-uppercase"><strong>Desconto Concedido</strong></label>
                          <v-select
                            v-model="parcelado[fPagamento][item]"
                            :clearable="false"
                            :options="descontoConcedido"
                          />
                        </div>
                        <div
                          v-else-if="item === 'nParcelas' && fPagamento !== 'cartao_credito'"
                        >
                          <label class="text-uppercase"><strong>{{ item }}</strong></label>
                          <b-form-spinbutton
                            v-model="parcelado[fPagamento][item]"
                            min="2"
                            :max="100"
                          />
                        </div>
                        <div
                          v-else-if="item === 'nParcelas' && fPagamento === 'cartao_credito'"
                        >
                          <div
                            v-for="key in Object.keys(parcelado[fPagamento][item])"
                            :key="key"
                            class="mb-1"
                          >
                            <div v-if="parcelado[fPagamento][item][key]">
                              <label class="text-uppercase"><strong>{{ key.split('_').join(' ') }}</strong></label>
                              <b-form-spinbutton
                                v-model="parcelado[fPagamento][item][key]"
                                min="2"
                                :max="100"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <label
                            class="text-uppercase"
                          >
                            <strong>{{ item.includes('_max') && item ? item.split('_').join(' ') : item.concat(' MIN') }}</strong>
                          </label>
                          <b-form-input
                            v-model="parcelado[fPagamento][item]"
                            type="number"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      ref="modal-duplicate"
      ok-title="Duplicar"
      ok-only
      :ok-disabled="!semestreSelected"
      size="lg"
      no-close-on-backdrop
      @ok="duplicarParametro()"
    >
      <b-row class="mb-1">
        <b-col>
          <b-row>
            <b-col>
              <h4>Período</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="">Selecione o ano</label>
              <v-select
                v-model="yearSelected"
                :options="setAnosDisponiveis"
                :disabled="periodoDisabled"
              />
            </b-col>
            <b-col>
              <label for="">Selecione o semestre</label>
              <v-select
                v-model="semestreSelected"
                :options="setSemestres"
                :disabled="periodoDisabled"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormInput,
  BFormSpinbutton,
  // BFormTextarea,
} from 'bootstrap-vue'
import { Money } from 'v-money'

import vSelect from 'vue-select'

// import { addYears } from 'date-fns'

// imports do text-area customizado
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormInput,
    vSelect,
    // BFormTextarea,
    quillEditor,
    Money,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      limitar_boleto: false,
      limitar_cartao: false,
      permissions: null,
      credorId: this.$route.params.id,
      credor: null,

      fields: ['ano', { key: 'semestre', label: 'semestre' }, { key: 'actions', label: 'ações' }],
      items: [],

      remuneracao: [
        { key: 'honorario', label: 'Hon. Variável' },
        { key: 'comissao', label: 'Hon. Fixo' },
      ],
      remuneracaoSelected: null,

      avista: {
        avaible: true,
        boleto_pix: {
          label: 'Boleto/PIX',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: null,
        },
        cartao_credito: {
          label: 'Cartão de Crédito',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: null,
        },
        portal: {
          label: 'Portal',
          avaible: false,
          juros: 1,
          multa: 2,
          desconto: 0,
          remuneracao: 0,
        },
      },

      parcelado: {
        avaible: true,
        boleto_pix: {
          label: 'Boleto/PIX',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          entrada: 50,
          desconto_concedido: 0,
          nParcelas: 2,
        },
        cartao_credito: {
          label: 'Cartão de Crédito',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 20,
          remuneracao_max: 20,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: 0,
          nParcelas: {
            cartao_voz: null,
            cartao_credor: null,
          },
        },
        portal: {
          label: 'Portal',
          avaible: false,
          juros: 1,
          multa: 2,
          desconto: 0,
          remuneracao: 0,
        },
      },

      dft: {
        convenio: null,
        correcao_monetario: null,
        opcao_cartao: null,
        ativo_portal: true,
        observacoes: null,
        comissao_fixa: 12,
        comissao_baixa_credor: 20,
        base_parcela: 0,
      },

      convenios: [],
      correcaoMonetarias: [
        { key: 'igpm', label: 'IGPM' },
        { key: 'inpc', label: 'INPC' },
      ],
      descontoConcedido: [
        { key: 'obrigatorio', label: 'Obrigatório' },
        { key: 'nao_obrigatorio', label: 'Não Obrigatório' },
        { key: 'nao_conceder', label: 'Não Conceder' },
      ],
      opcaoCartao: [
        { key: 'cartao_voz', label: 'Cartão Voz' },
        { key: 'cartao_credor', label: 'Cartão Credor' },
      ],

      defaultEdit: true,

      yearSelected: null,
      semestreSelected: null,

      periodoDisabled: false,
      paramDuplicate: null,
      anoDuplicar: null,
      semestreDuplicar: null,
    }
  },
  computed: {
    isSituacao() {
      const { credor } = this
      if (credor) {
        const { id } = credor
        if (id === parseInt(process.env.VUE_APP_ROOT_FAFIRE) || id === parseInt(process.env.VUE_APP_ROOT_EDUCA_MAIS) || id === parseInt(process.env.VUE_APP_ROOT_i9)) {
          return true
        }
      }
      return false
    },
    setAnosDisponiveis() {
      const { items: usedParams } = this
      const suposKeys = []

      const items = []

      const currentYear = (new Date()).getFullYear()
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))

      const yearsRange = range(currentYear, currentYear - 5, -1)
      yearsRange.map(item => {
        suposKeys.push({
          ano: item.toString(),
          semestre: '1',
        })
        suposKeys.push({
          ano: item.toString(),
          semestre: '2',
        })
      })

      usedParams.map(used => {
        suposKeys.map(year => {
          if (year.ano === used.ano && year.semestre === used.semestre) {
            const index = suposKeys.indexOf(year)
            if (index > -1) {
              suposKeys.splice(index, 1)
            }
          }
        })
      })

      suposKeys.map(item => {
        items.push(item.ano)
      })
      return [...new Set(items)]
    },

    setSemestres() {
      const { yearSelected } = this
      const { items: usedParams } = this
      let items = []
      if (yearSelected) {
        items = [{ label: '1', value: '1' }, { label: '2', value: '2' }]
        if (this.isSituacao) {
          items = [{ label: 'ATIVO', value: '1' }, { label: 'INATIVO', value: '2' }]
        }
        usedParams.map(item => {
          if (item.ano === yearSelected) {
            item.semestre === '1' ? items = items.filter(e => e.value !== '1') : null
            item.semestre === '2' ? items = items.filter(e => e.value !== '2') : null
          }
        })
      }
      return items
    },

    verifyOpcaoCartao() {
      const { opcao_cartao } = this.dft
      // eslint-disable-next-line camelcase
      if (!opcao_cartao || opcao_cartao.length === 0) {
        this.parcelado.cartao_credito.avaible = false
        return true
      }
      opcao_cartao.map(item => {
        this.parcelado.cartao_credito.nParcelas[item.key] ? null : this.parcelado.cartao_credito.nParcelas[item.key] = 2
      })
      return false
    },
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).credores
    await axios.get(`api/v1/credores/edit/${this.credorId}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.token),
        Accept: 'application/json',
      },
    }).then(async res => {
      this.credor = res.data.dados.credores
      await this.ordenarParametros()
      this.separateParametro()
    })
  },
  mounted() {
    this.getConvenios()
  },

  methods: {
    getConvenios() {
      axios.get('api/v1/parametro_boleto', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      }).then(res => {
        const { dados } = res.data
        if (dados) {
          this.convenios = dados
        }
      })
    },
    async setDefaultParametro() {
      const { dft } = this
      this.credor.parametros_config.default = dft

      await this.updateCredor()

      this.defaultEdit = true
    },
    async setNewParametro() {
      const {
        yearSelected,
        semestreSelected,
      } = this

      const {
        avista,
        parcelado,
      } = this

      const avistaJson = {}
      const parceladoJson = {}

      if (avista.avaible) {
        const avistaKeys = Object.keys(avista)

        avistaJson.avaible = avista.avaible
        avistaKeys.map(item => {
          if (avista[item].avaible) {
            avistaJson[item] = avista[item]
          }
        })
      }

      if (parcelado.avaible) {
        const parceladoKeys = Object.keys(parcelado)
        parceladoJson.avaible = parcelado.avaible

        parceladoKeys.map(item => {
          if (parcelado[item].avaible) {
            if (item === 'cartao_credito') {
              const { opcao_cartao } = this.dft
              opcao_cartao.map(card => {
                parceladoJson[card.key] = parcelado[item]
                parceladoJson[card.key].label = card.label
                parceladoJson[card.key].nParcelas = parcelado[item].nParcelas[card.key]
              })
            } else {
              parceladoJson[item] = parcelado[item]
            }
          }
        })
      }

      const IMA_LIST = [
        161,
        112,
        140,
        139,
        115,
        105,
        76,
        126,
        51,
      ]

      if (IMA_LIST.includes(this.credor.id) && parceladoJson.cartao_credor) {
        parceladoJson.maquineta = { ...parceladoJson.cartao_credor }
        parceladoJson.maquineta.label = 'Maquineta'
      }

      if (!this.credor.parametros_config) {
        this.credor.parametros_config = {}
      }

      this.credor.parametros_config[`${yearSelected}.${semestreSelected.value}`] = {
        remuneracao: this.remuneracaoSelected,
        avista: avistaJson,
        parcelado: parceladoJson,
        limitarBoleto: this.limitar_boleto,
        limitarCartao: this.limitar_cartao,
      }

      this.updateCredor()
    },

    updateParametro(item) {
      const { ano, semestre } = item
      const parametro = this.credor.parametros_config[`${ano}.${semestre}`]

      Object.keys(parametro).map(modo => {
        Object.keys(parametro[modo]).map(metodo => {
          if (modo === 'parcelado') {
            const { opcao_cartao: opCartao } = this.dft
            if (metodo === 'boleto_pix') {
              this[modo][metodo] = parametro[modo][metodo]
            } else if (opCartao) {
              opCartao.map(op => {
                if (parametro[modo][op.key]) {
                  const keys = Object.keys(parametro[modo][op.key]).filter(e => e !== 'label' && e !== 'nParcelas')
                  keys.map(cv => {
                    this[modo].cartao_credito[cv] = parametro[modo][op.key][cv]
                  })
                  if (op.label === parametro[modo][op.key].label) {
                    this[modo].cartao_credito.nParcelas[op.key] = parametro[modo][op.key].nParcelas
                  }
                }
              })
            }
          } else if (modo === 'avista' && metodo !== 'avaible') {
            this[modo][metodo] = parametro[modo][metodo]
          }
        })
      })

      this.periodoDisabled = true

      this.limitar_boleto = parametro.limitarBoleto
      this.limitar_cartao = parametro.limitarCartao

      this.remuneracaoSelected = parametro.remuneracao

      this.yearSelected = ano
      const semestreSelected = () => {
        if (this.isSituacao) {
          return { value: semestre, label: semestre === '1' ? 'ATIVO' : 'INATIVO' }
        }

        return { value: semestre, label: semestre }
      }
      this.semestreSelected = semestreSelected()

      this.openModalnewParametro()
    },

    // eslint-disable-next-line no-unused-vars
    deleteParametro(item) {
      this.$swal({
        title: '',
        text: 'Deseja confirmar a exclusão do paramêtro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'sim, eu confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const key = `${item.ano}.${item.semestre}`
          const { parametros_config: parametros } = this.credor
          delete parametros[key]
          this.credor.parametros_config = parametros

          await this.updateCredor()
        }
      })
    },

    async updateCredor() {
      await axios.put(`api/v1/credores/update/${this.credor.id}`, this.credor, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Operação Concluída',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })

      await this.ordenarParametros()

      this.separateParametro()
      this.clearForm()
    },

    clearForm() {
      this.yearSelected = null
      this.semestreSelected = null
      this.remuneracaoSelected = null

      this.avista = {
        avaible: true,
        boleto_pix: {
          label: 'Boleto/PIX',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: 0,
        },
        cartao_credito: {
          label: 'Cartão de Crédito',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: null,
        },
        portal: {
          label: 'Portal',
          avaible: false,
          juros: 1,
          multa: 2,
          desconto: 0,
          remuneracao: 0,
        },
      }

      this.parcelado = {
        avaible: true,
        boleto_pix: {
          label: 'Boleto/PIX',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 0,
          remuneracao_max: 0,
          desconto: 0,
          desconto_max: 0,
          entrada: 50,
          desconto_concedido: 0,
          nParcelas: 2,
        },
        cartao_credito: {
          label: 'Cartão de Crédito',
          avaible: false,
          juros: 1,
          juros_max: 1,
          multa: 2,
          multa_max: 2,
          remuneracao: 20,
          remuneracao_max: 20,
          desconto: 0,
          desconto_max: 0,
          desconto_concedido: 0,
          nParcelas: {
            cartao_voz: null,
            cartao_credor: null,
          },
        },
        portal: {
          label: 'Portal',
          avaible: false,
          juros: 1,
          multa: 2,
          desconto: 0,
          remuneracao: 0,
        },
      }

      this.periodoDisabled = false
    },

    separateParametro() {
      const { parametros_config: parametros } = this.credor
      const items = []

      if (parametros) {
        const chaves = Object.keys(parametros).filter(e => e !== 'default')

        chaves.map(key => {
          const a = key.split('.')
          items.unshift({
            ano: a[0],
            semestre: a[1],
          })
        })

        if (parametros.default) {
          this.dft = parametros.default
          !this.dft.not_debito ? this.dft.not_debito = {
            ativo: false,
            desconto: 5,
            remuneracao: 0,
            juros: 1,
            multa: 2,
          } : null
        }
      }

      this.items = items
    },

    openModalnewParametro() {
      this.$refs.newParametro.show()
    },

    ordenarParametros() {
      const { parametros_config: parametros } = this.credor

      const keys = {}

      const periodos = Object.keys(parametros).sort((a, b) => ((a > b) ? 1 : -1))

      periodos.map(item => {
        keys[item] = parametros[item]
      })

      this.credor.parametros_config = keys
    },

    onRowSelected() {
    },

    setModalDuplicar(item) {
      this.paramDuplicate = item
      this.$refs['modal-duplicate'].show()
    },

    duplicarParametro() {
      const { yearSelected, semestreSelected } = this
      const { ano, semestre } = this.paramDuplicate

      this.credor.parametros_config[`${yearSelected}.${semestreSelected.value}`] = this.credor.parametros_config[`${ano}.${semestre}`]

      this.updateCredor()
    },

    formSubmitted() {},
  },
}
</script>
