const roles = []

const { menu } = JSON.parse(localStorage.permissoes)

const credorExists = (localStorage.getItem('credores'))

// const cpfMaster = [
//   '06982749380',
//   '61037007360',
//   '01076601219',
// ]
// const userCpf = localStorage.cpf

if (credorExists) {
  roles.push({
    title: 'Dashboard',
    icon: 'AirplayIcon',
    children: [
      {
        title: 'Meu Dashboard',
        route: 'meuDash',
      },
    ],
  })
} else {
  roles.push({
    title: 'Dashboard',
    icon: 'AirplayIcon',
    children: [
      {
        title: 'Meu Dashboard',
        route: 'meuDash',
      },
      {
        title: 'Remessas',
        route: 'dashRemessas',
      },
      {
        title: 'Faturamento',
        route: 'dashFaturamento',
      },
      {
        title: 'PowerBI',
        route: 'linkBI',
      },
      {
        title: 'Metas',
        route: 'metas',
      },
      {
        title: 'Metas Diárias',
        route: 'metasDiarias',
      },
    ],
  })
}

// ADMINISTRADOR
if (menu['menu.administrador'].permissao) {
  roles.push({
    title: 'Administrador',
    icon: 'TerminalIcon',
    children: [
      {
        title: 'Cadastros',
        route: 'administrador.cadastro',
      },
    ],
  })
}

// ADMINISTRATIVO
if (menu['menu.administrativo'].permissao) {
  roles.push({
    title: 'Administrativo',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Cadastros',
        route: 'administrativo.cadastro',
      },
      {
        title: 'Fornecedor',
        route: 'administrativo.fornecedor',
      },
      {
        title: 'Template Sms',
        route: 'administrativo.templatesms',
      },
      {
        title: 'Template Email',
        route: 'administrativo.templateemail',
      },
      {
        title: 'Template WhatsApp',
        route: 'administrativo.templatewhatsapp',
      },
    ],
  })
}

// BACKOFFICE
if (menu['menu.backoffice'].permissao) {
  roles.push({
    title: 'Backoffice',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'backoffice',
      },
      {
        title: 'Clientes',
        route: 'clientes',
      },
      {
        title: 'Credores',
        route: 'credores',
      },
      {
        title: 'Devolução',
        route: 'devolucao',
      },
      {
        title: 'Operação',
        route: 'operacao',
      },
      {
        title: 'Remessas',
        route: 'remessas',
      },
      {
        title: 'Campanhas',
        route: 'campanhas',
      },
      {
        title: 'Importação',
        route: 'importacao',
      },
      {
        title: 'Insucessos',
        route: 'insucessos',
      },
      {
        title: 'Regua de Cobrança',
        route: 'regua',
      },
      {
        title: 'Roteiro',
        route: 'roteiro',
      },
    ],
  })
}

// COBRANCA
if (menu['menu.cobranca'].permissao) {
  roles.push({
    title: 'Cobrança',
    icon: 'ThumbsUpIcon',
    children: [
      {
        title: 'Negociação',
        route: 'negociacao',
      },
      {
        title: 'Acordos',
        route: 'acordos',
      },
      {
        title: 'Parcelas',
        route: 'parcelas',
      },
      {
        title: 'Campanhas Manuais',
        route: 'campanha.manual',
      },
    ],
  })
}

// CREDORES
if (menu['menu.credor'].permissao) {
  roles.push({
    title: 'Credor',
    icon: 'TargetIcon',
    children: [
      {
        title: 'Acordos',
        route: 'credor.acordos',
      },
      {
        title: 'Clientes',
        route: 'credor.cliente',
      },
      // {
      //   title: 'Campanhas',
      //   route: 'credor.campanha',
      // },
      {
        title: 'Remessas',
        route: 'credor.remessa',
      },
      {
        title: 'Operações',
        route: 'credor.operacoes',
      },
      {
        title: 'Recebimentos',
        route: 'credor.recebimentos',
      },
      {
        title: 'Eventos',
        route: 'credor.eventos',
      },
    ],
  })
}

// FINANCEIRO
if (menu['menu.financeiro'].permissao) {
  roles.push({
    title: 'Financeiro',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Baixa de Boletos',
        route: 'financeiro.boletos',
      },
      {
        title: 'Recebimentos',
        route: 'financeiro.prestacao',
      },
      {
        title: 'Negativação',
        route: 'financeiro.negativacaocliente',
      },
      {
        title: 'Convênio',
        route: 'financeiro.convenio',
      },
    ],
  })
}

// RELATÓRIOS
if (menu['menu.financeiro'].permissao) {
  roles.push({
    title: 'Relatórios',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'Eventos',
        route: 'relatorio.eventos',
      },
      {
        title: 'Contato',
        route: 'relatorio.contato',
      },
      {
        title: 'Chamadas',
        route: 'relatorio.chamadas',
      },
      {
        title: 'Operações',
        route: 'relatorio.operacoes',
      },
      {
        title: 'Faturamento',
        route: 'relatorio.prestacao_contas',
      },
      {
        title: 'Ações',
        route: 'relatorio.acoes',
      },
      {
        title: 'Devoluções',
        route: 'relatorio.devolucao',
      },
      {
        title: 'Prestação de Conta',
        route: 'relatorio.contas',
      },
      {
        title: 'Não Acionados',
        route: 'relatorio.nao_acionados',
      },
      {
        title: 'Extração',
        route: 'relatorio.extracao',
      },
    ],
  })
}
roles.push({
  title: 'Versões',
  icon: 'ListIcon',
  route: 'versoes',
})

const allowedCPFs = [
  '61037007360',
  '61097544397',
  // '01076',
]

const userData = JSON.parse(localStorage.userData)
if (userData) {
  const { cpf } = userData
  allowedCPFs.map(item => {
    if (item === cpf) {
      roles.map(role => {
        if (role.title === 'Financeiro') {
          role.children.push({
            title: 'Integração Rede Damas',
            route: 'integracao-rede-damas',
            icon: 'GitPullRequestIcon',
          })
        }
      })
    }
  })
}

// const tree = roles[localStorage.getItem('acesso_id')]
const tree = roles

export default tree
