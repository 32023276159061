<template>
  <div>
    <b-button
      class="mb-1"
      variant="flat-primary"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-row>
      <b-col md="3" />
      <b-col>
        <h4>{{ credor.nome }}</h4>
      </b-col>
    </b-row>
    <b-tabs
      horizontal
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >

      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="AirplayIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">DashBoard</span>
        </template>

        <CredorDashboard
          v-if="loadCredor"
          :general-data="credor.id"
        />

      </b-tab>
      <!-- general tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Informações Gerais</span>
        </template>

        <CredorGeral
          v-if="loadCredor"
          :general-data="credor"
        />

      </b-tab>
      <!--/ general tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="PhoneCallIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Representantes</span>
        </template>
        <CredorContatos />

      </b-tab>

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="SlidersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Parâmetros</span>
        </template>
        <CredorParametro />

      </b-tab>
      <!--/ change password tab -->

      <!-- social links -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Remessas</span>
        </template>

        <CredorRemessa
          v-if="loadRemessa"
          :remessas="Remessas"
        />

      </b-tab>

      <!-- notification -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Clientes</span>
        </template>

        <CredorCliente
          v-if="loadCliente"
          :clientes="Clientes"
        />

      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
import { BTabs, BTab, BButton } from 'bootstrap-vue'
import CredorDashboard from '@/views/backoffice/credores/cards/CredorDashboard.vue'
import CredorRemessa from '@/views/backoffice/credores/cards/CredorRemessa.vue'
import CredorCliente from '@/views/backoffice/credores/cards/CredorCliente.vue'
import CredorParametro from '@/views/backoffice/credores/cards/CredorParametro.vue'
import CredorGeral from './cards/CredorGeral.vue'
import CredorContatos from './cards/CredorContatos.vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    CredorDashboard,
    CredorParametro,
    CredorCliente,
    CredorRemessa,
    CredorGeral,
    CredorContatos,
    BButton,
    BTabs,
    BTab,
  },
  data() {
    return {
      parametro: null,
      Clientes: null,
      Remessas: null,
      operacoes: {
        numero_operacao: 1,
        cliente_id: 1,
        cliente_nome: 1,
        cliente_cpf_cnpj: 1,
        data_vencimento: 1,
        valor_nominal: 1,
      },
      loadParametro: false,
      loadCredor: false,
      loadCliente: false,
      loadRemessa: false,
      clienteId: null,
      emails: [
        {
          id: 1,
          email: 'jairo@grupovoz.com.br',
        },
      ],
      telefones: [
        {
          id: 1,
          telefone: '99 99631-6429',
        },
      ],
      credor: {
        id: null,
        nome: null,
        bairro: null,
        cep: null,
        cnpj: null,
        endereco: null,
        cidade_id: null,
        uf: null,
        rua: null,
        recebimento: null,
        numero: null,
        inscricaoEstadual: null,
        aliasCompleto: null,
        grupoCredores: null,
        aliasAbreviado: null,
        razaoSocial: null,
        statusemProcesso: null,
        statusnaoLocalizado: null,
        status: null,
        email: null,
        telefone: null,
        prazoRemessa: null,
        negativacao: null,
        gerarNumeroOperacao: null,
        tipoCredor: null,
        prazoHonorario: null,
        tipoRemessa: null,
        diasExtracao: null,
      },
      options: {
        general: {
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
          username: 'johndoe',
          fullName: 'John Doe',
          email: 'granger007@hogward.com',
          company: 'Crystal Technologies',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              // eslint-disable-next-line global-require
              profileImg: require('@/assets/images/avatars/11-small.png'),
              id: 'johndoe',
            },
            google: {
              // eslint-disable-next-line global-require
              profileImg: require('@/assets/images/avatars/3-small.png'),
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    }
  },
  beforeCreate() {
    axios.get(`api/v1/credores/edit/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.credor.nome = res.data.dados.credores.nome
        this.credor.id = res.data.dados.credores.id
        this.credor.cnpj = res.data.dados.credores.cnpj
        this.credor.endereco = res.data.dados.credores.endereco
        this.credor.cidade_id = res.data.dados.credores.cidade_id
        this.credor.numero = res.data.dados.credores.numero
        this.credor.bairro = res.data.dados.credores.bairro
        this.credor.cep = res.data.dados.credores.cep
        this.credor.rua = res.data.dados.credores.rua
        this.credor.recebimento = res.data.dados.credores.recebimento
        this.credor.razaoSocial = res.data.dados.credores.razao_social
        this.credor.aliasCompleto = res.data.dados.credores.alias_completo
        this.credor.grupoCredores = res.data.dados.credores.rede_id
        this.credor.aliasAbreviado = res.data.dados.credores.alias_abreviado
        this.credor.inscricaoEstadual = res.data.dados.credores.inscricao_estadual
        this.credor.email = res.data.dados.credores.email
        this.credor.telefone = res.data.dados.credores.telefone
        this.credor.statusemProcesso = res.data.dados.credores.status_em_processo
        this.credor.statusnaoLocalizado = res.data.dados.credores.status_nao_localizado
        this.credor.status = res.data.dados.credores.status
        this.credor.prazoRemessa = res.data.dados.credores.prazo_remessa
        this.credor.negativacao = res.data.dados.credores.negativacao
        this.credor.gerarNumeroOperacao = res.data.dados.credores.gerar_numero_operacao
        this.credor.tipoCredor = res.data.dados.credores.tipo_credor
        this.credor.prazoHonorario = res.data.dados.credores.prazo_honorario
        this.credor.diasExtracao = JSON.parse(res.data.dados.credores.dias_extracao)
        this.credor.tipoRemessa = res.data.dados.credores.tipo_remessa
        this.loadCredor = true

        // BUSCANDO PARAMETROS
        // this.parametro = res.data.dados.credores.parametros_config
      })
    axios.get(`api/v1/credores/clientes/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.loadCliente = true
        this.Clientes = res.data.dados
      })
    axios.get(`api/v1/credores/remessas/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.loadRemessa = true
        this.Remessas = res.data
        // eslint-disable-next-line array-callback-return
        this.Remessas.map(dt => {
          // eslint-disable-next-line no-param-reassign
          dt.data_remessa = dt.data_remessa ? this.dataHora(dt.data_remessa) : ''
        })
      })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
